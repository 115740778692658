import { treaty } from "@elysiajs/eden";
import {
  CoreServerType,
  InferModel,
  Goal,
  Gender,
  Hobbies,
  BotPronouns,
  BotEmotionalAttributes,
  BotQualityAttributes,
  BotPersonalityAttributes,
  BotApproach,
  BotConversationDepth,
  BotBodyShape,
  BotBodyUpper,
  BotBodyLower,
  BotRoleplayTheme,
} from "@lovelica/api-client";
import { ApiErrorCodeType, DomainErrorType } from "@lovelica/api-client";
import { coreapi } from "./coreapi";

export type { DomainErrorType };

export type {
  Goal,
  Gender,
  Hobbies,
  BotPronouns,
  BotEmotionalAttributes,
  BotQualityAttributes,
  BotPersonalityAttributes,
  BotApproach,
  BotConversationDepth,
  BotBodyShape,
  BotBodyUpper,
  BotBodyLower,
  BotRoleplayTheme,
};

//remove this
const eden = treaty<CoreServerType>("");

export type ServerUser = InferModel<typeof eden.getUserByID.get>;

export type ServerBot = InferModel<typeof eden.getBot.get>;

export type ServerMessage = InferModel<typeof eden.createMessage.post>["message"];

export type ServerPricingPlan = Awaited<ReturnType<typeof coreapi.getPricingPlans>>[0];

export type ServerResponseError = {
  status: number;
  value:
    | string
    | {
        code: ApiErrorCodeType;
        type?: DomainErrorType;
        userMessage?: string;
      };
};

export type EnsureUserInput = {
  gender: Gender;
  email: string;
  age: string;
  goal: Goal;
  botID: string;
  botEmotionalAttributes: BotEmotionalAttributes[];
  botApproach: BotApproach;
  botConversationDepth: BotConversationDepth;
  botBodyShape: BotBodyShape;
  botBodyUpper: BotBodyUpper;
  botBodyLower: BotBodyLower;
  botRoleplayTheme: BotRoleplayTheme;
};
