import * as React from "react";
import { Container, Slide } from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { PaymentForm } from "./PaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import { siteConfig } from "src/conf";
import { useLocation } from "@gatsbyjs/reach-router";
import { useStore } from "../StoreProvider";
import { ServerPricingPlan } from "@services/coreapi";
import { PricingPlan } from "src/types";
import { toPricingPlan } from "@utils/pricingPlans";

const stripe = loadStripe(siteConfig.stripePublicKey);

export function PaymentProvider({ children }: React.PropsWithChildren<{}>) {
  const location = useLocation();
  const { pricingPlans } = useStore();

  const [plan, setPlan] = React.useState<PricingPlan | null>(null);

  React.useEffect(() => {
    if (location.pathname.includes("/checkout")) {
      const plan = resolvePlan(pricingPlans);
      if (plan) {
        setPlan(plan);
      } else {
        console.error("No plan found for checkout");
      }
    }

    return () => {
      setPlan(null);
    };
  }, [location, pricingPlans]);

  function resolvePlan(allPlans: ServerPricingPlan[]) {
    const params = new URLSearchParams(location.search);
    const productID = params.get("product-id");
    if (!productID) {
      return;
    }

    const res = allPlans.find((it) => it.product.id === productID);
    if (!res) {
      return;
    }

    const couponID = params.get("coupon-id") ?? undefined;
    return toPricingPlan(res, couponID);
  }

  return (
    <Elements
      stripe={stripe}
      options={{
        mode: "subscription",
        amount: 0,
        currency: "usd",
        appearance: {
          theme: "stripe",
        },
      }}
    >
      {children}

      {plan && (
        <Slide
          direction="bottom"
          in={true}
          style={{ zIndex: 10, backgroundColor: "white", boxShadow: "0px 10px 20px 0px black" }}
        >
          <Container py={5}>
            <PaymentForm plan={plan} />
          </Container>
        </Slide>
      )}
    </Elements>
  );
}
