import { ServerBot } from "@services/coreapi";
import { IGatsbyImageData } from "gatsby-plugin-image";

export type BotSimple = ServerBot & { profileImage: IGatsbyImageData };

export type BotExtended = ServerBot & {
  images: IGatsbyImageData[];
  video?: string;
  audio?: string;
};

export const offerIDs = ["offer1", "offer2", "offer3"] as const;

export type OfferID = (typeof offerIDs)[number];

export type Offer = {
  id: string;
  couponID: string;
  amount_off: number;
  percent_off: number;
};

export type PricingPlan = {
  couponID?: string;
  currency: string;
  priceID: string;
  productID: string;
  name: string;
  discount?: number;
  billingText: string;
  labelText?: string;
  unitAmount: number;
  unitAmountBefore: number;
  unitAmountDaily: number;
  unitAmountDailyBefore: number;
  stripe: {
    unitAmount: number;
  };
};
