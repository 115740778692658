import * as React from "react";
import { GatsbyBrowser } from "gatsby";
import { posthog } from "posthog-js";
import { navigate } from "gatsby";

import { initBrevo, initPosthog, trackBrevoPageView, trackPixelEvent } from "./src/utils/tracking";
import { RootWrapper } from "./src/wrappers/RootWrapper";
import { PageWrapper } from "./src/wrappers/PageWrapper";
import { siteConfig } from "./src/conf";
import { sessionCache } from "./src/sessionCache";
import { createProductURL } from "./src/utils/url";

import "@fontsource-variable/manrope";
import "@fontsource-variable/inter";

// @ts-ignore
import pkgjson from "./package.json";

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  initPosthog(siteConfig.posthogKey, siteConfig.posthogHost, pkgjson.version);
  if (siteConfig.brevoKey) {
    initBrevo({ clientKey: siteConfig.brevoKey });
  }
};

export const onPreRouteUpdate: GatsbyBrowser["onPreRouteUpdate"] = ({ location }) => {
  const hasPurchased = sessionCache.getPurchaseStatus();
  const isInFunnel = /\/funnel-1\/.+/.test(location.pathname);

  if (isInFunnel && hasPurchased) {
    console.warn(`Redirecting to the app: user has already purchased`);
    navigate(createProductURL());
    return;
  }
};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = () => {
  posthog.capture("$pageview");
  trackPixelEvent("ViewContent");
  // document.title never changes, so use pathname as page title
  trackBrevoPageView(location.pathname, location.pathname);
};

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return <RootWrapper>{element}</RootWrapper>;
};

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => {
  return <PageWrapper>{element}</PageWrapper>;
};
