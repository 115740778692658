import { PricingPlan } from "src/types";
import { ServerPricingPlan } from "@services/coreapi";

export function createCheckoutPathname(pricingPlan: PricingPlan): string {
  const params = new URLSearchParams();
  params.set("product-id", pricingPlan.productID);
  if (pricingPlan.couponID) {
    params.set("coupon-id", pricingPlan.couponID);
  }

  return `/funnel-1/checkout?${params.toString()}`;
}

export function toPricingPlan(plan: ServerPricingPlan, couponID?: string): PricingPlan {
  function truncateToTwoDecimals(num: number): string {
    const [intPart, decPart = ""] = num.toString().split(".");
    return `${intPart}.${(decPart + "00").slice(0, 2)}`;
  }

  function getDayCount(plan: ServerPricingPlan): number {
    if (!plan.price.recurring) {
      return 0;
    }

    const { interval, interval_count } = plan.price.recurring;
    switch (interval) {
      case "day":
        return 1 * interval_count;
      case "week":
        return 7 * interval_count;
      case "month":
        return 30 * interval_count;
      case "year":
        return 365 * interval_count;
      default:
        return 0;
    }
  }

  const dayCount = getDayCount(plan);
  const offer = plan.offers.find((it) => it.couponID === couponID);

  const amountOff = offer?.amount_off ? offer.amount_off / 100 : 0;

  const unitAmountBefore = plan.price.unit_amount / 100;
  const unitAmount = offer ? unitAmountBefore - amountOff : unitAmountBefore;

  const unitAmountDailyBefore = unitAmountBefore / dayCount;
  const unitAmountDaily = unitAmount / dayCount;

  return {
    couponID,
    currency: plan.price.currency,
    priceID: plan.price.id,
    productID: plan.product.id,
    name: plan.product.name,
    billingText: plan.product.billing_text,
    labelText: plan.product.label_text,
    discount: offer?.percent_off,
    unitAmount: Number(unitAmount.toFixed(2)),
    unitAmountBefore,
    unitAmountDaily: Number(truncateToTwoDecimals(unitAmountDaily)),
    unitAmountDailyBefore: Number(truncateToTwoDecimals(unitAmountDailyBefore)),
    stripe: {
      unitAmount: plan.price.unit_amount,
    },
  };
}
