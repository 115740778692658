import * as React from "react";

import { useStripe, useElements } from "@stripe/react-stripe-js";
import { trackEvent, trackPixelEvent } from "@utils/tracking";
import { coreapi } from "@services/coreapi";
import { useStore } from "../StoreProvider";
import { createSuccessCheckoutURL } from "@utils/url";
import { PricingPlan } from "src/types";
import { PaymentRequest } from "./types";

export function usePayment(): [
  PaymentRequest,
  (paymentType: "express" | "card", plan: PricingPlan) => Promise<void>
] {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useStore();

  const [request, setRequest] = React.useState<PaymentRequest>({
    state: "initial",
  });

  async function submit(paymentType: "express" | "card", plan: PricingPlan) {
    if (!stripe || !elements || !user) {
      console.error("Data missing for subscription setup");
      setRequest({
        state: "error",
        error: "Something went wrong. Please try again",
      });

      return;
    }

    setRequest({ state: "loading" });

    const redirectUrl = createSuccessCheckoutURL({
      paymentType: paymentType,
      amount: plan.unitAmount,
      currency: plan.currency,
      priceID: plan.priceID,
      productID: plan.productID,
    });

    trackPixelEvent("AddPaymentInfo", {
      value: plan.unitAmount,
      currency: plan.currency,
      priceID: plan.priceID,
      productID: plan.productID,
    });

    trackEvent({
      name: "add-payment-info",
      properties: {},
    });

    try {
      elements.update({
        amount: plan.stripe.unitAmount,
        currency: plan.currency,
      });

      // Trigger form validation and wallet collection
      const elementSubmission = await elements.submit();
      if (elementSubmission.error) {
        const msg =
          elementSubmission.error.message ?? "Payment failed. Try another card or payment method";
        console.error(msg);
        setRequest({
          state: "error",
          error: msg,
        });

        return;
      }

      const subscription = await coreapi.createSubscription({
        userID: user.id,
        priceID: plan.priceID,
        couponID: plan.couponID,
      });

      if (subscription.error) {
        console.error(subscription.error.message);
        setRequest({
          state: "error",
          error: "Something went wrong. Please try again",
        });

        return;
      }

      if (!subscription.data) {
        console.error("Subscription data is missing");
        setRequest({
          state: "error",
          error: "Something went wrong. Please try again",
        });

        return;
      }

      // Empty client secret means that overall price of the cart is very close to 0.
      // In that case subscription becomes automatically activated.
      // GOOD FOR TESTING!
      if (!subscription.data.client_secret) {
        console.error("No client secret, payment has been paid");
        setRequest({
          state: "error",
          error: "Something went wrong. Please try again",
        });

        return;
      }

      const confirmation = await stripe.confirmPayment({
        elements,
        clientSecret: subscription.data.client_secret,
        confirmParams: {
          return_url: redirectUrl.toString(),
        },
      });

      if (confirmation.error) {
        const msg =
          confirmation.error.message ??
          "Your payment has been declined. Try another card or payment method";
        console.error(msg);
        setRequest({
          state: "error",
          error: msg,
        });

        return;
      }

      setRequest({ state: "ok" });
    } catch (err) {
      console.error(err);
      setRequest({ state: "error", error: "Something went wrong. Please try again" });
    }
  }

  return [request, submit];
}
