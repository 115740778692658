import { OfferID, PricingPlan } from "src/types";
import { ServerPricingPlan } from "@services/coreapi";
import { createCheckoutPathname, toPricingPlan } from "@utils/pricingPlans";
import { navigate } from "gatsby";
import React from "react";

type ExitIntentContextType = {
  /**
   * Calculates exit intent programatically based on the state
   */
  determineNextExitIntent: () => void;
  setSelectedPricingPlan: (plan: ServerPricingPlan) => void;
  exitIntent: ExitIntent | null;
  isViewEnabled: boolean;
  setIsViewEnabled: (value: boolean) => void;
};

const ExitIntentContext = React.createContext<ExitIntentContextType | null>(null);

type ExitIntentType = "exit-intent-1" | "exit-intent-2";

export type ExitIntent = {
  id: ExitIntentType;
  pricingPlan: PricingPlan;
  onDecline: () => void;
  onAccept: () => void;
};

export function ExitIntentProvider({ children }: { children: React.ReactNode }) {
  /**
   * isViewEnabled is not used in the logic, but it is used to determine if the exit intent should be shown or not.
   */
  const [isViewEnabled, setIsViewEnabled] = React.useState<boolean>(true);
  const [selectedPricingPlan, setSelectedPricingPlan] = React.useState<ServerPricingPlan | null>(
    null
  );
  const [exitIntentID, setExitIntentID] = React.useState<ExitIntentType | null>(null);
  const [exitIntent, setExitIntent] = React.useState<ExitIntent | null>(null);

  React.useEffect(() => {
    const exitIntent = calculateExitIntent(exitIntentID, selectedPricingPlan);
    setExitIntent(exitIntent);
  }, [exitIntentID, selectedPricingPlan]);

  function calculateExitIntent(
    exitIntentID: ExitIntentType | null,
    selectedPricingPlan: ServerPricingPlan | null
  ): ExitIntent | null {
    if (selectedPricingPlan == null) {
      return null;
    }

    switch (exitIntentID) {
      case null: {
        return null;
      }
      case "exit-intent-1": {
        const offerID = "offer2";
        const pricingPlan = createPricingPlan(selectedPricingPlan, offerID);
        const checkoutPathname = createCheckoutPathname(pricingPlan);

        return {
          id: "exit-intent-1",
          pricingPlan,
          onDecline: () => {
            setExitIntentID("exit-intent-2");
            setSelectedPricingPlan(selectedPricingPlan);
          },
          onAccept: () => {
            navigate(checkoutPathname);
          },
        };
      }
      case "exit-intent-2": {
        const offerID = "offer3";
        const pricingPlan = createPricingPlan(selectedPricingPlan, offerID);
        const checkoutPathname = createCheckoutPathname(pricingPlan);

        return {
          id: "exit-intent-2",
          pricingPlan,
          onDecline: () => {
            setExitIntentID(null);
            setSelectedPricingPlan(selectedPricingPlan);
          },
          onAccept: () => {
            navigate(checkoutPathname);
          },
        };
      }
      default: {
        return null;
      }
    }
  }

  function createPricingPlan(pricingPlan: ServerPricingPlan, offerID: OfferID): PricingPlan {
    const offer = pricingPlan.offers.find((it) => it.id === offerID);
    return toPricingPlan(pricingPlan, offer?.couponID);
  }

  const value = React.useMemo(() => {
    return {
      exitIntent,
      setSelectedPricingPlan,
      isViewEnabled,
      setIsViewEnabled,
      determineNextExitIntent: () => {
        /**
         * If selected plan exists it means that user has already initiated checkout some time ago.
         */
        if (selectedPricingPlan == null) {
          return;
        }

        switch (exitIntentID) {
          case null: {
            setExitIntentID("exit-intent-1");
            setSelectedPricingPlan(selectedPricingPlan);
            break;
          }
          case "exit-intent-1": {
            setExitIntentID("exit-intent-2");
            setSelectedPricingPlan(selectedPricingPlan);
            break;
          }
          case "exit-intent-2": {
            setExitIntentID(null);
            setSelectedPricingPlan(null);
            break;
          }
          default: {
            setExitIntentID(null);
            setSelectedPricingPlan(null);
          }
        }
      },
    };
  }, [exitIntentID, exitIntent, selectedPricingPlan, isViewEnabled]);

  return <ExitIntentContext.Provider value={value}>{children}</ExitIntentContext.Provider>;
}

export function useExitIntentProvider() {
  const context = React.useContext(ExitIntentContext);
  if (!context) {
    throw new Error("useExitIntentProvider must be used within a ExitIntentProvider");
  }
  return context;
}
