import { ChakraTheme, DeepPartial, extendTheme } from "@chakra-ui/react";

export const white = {
  50: "white",
  100: "white",
  200: "white",
  300: "white",
  400: "white",
  500: "white",
  600: "white",
  700: "white",
  800: "white",
  900: "white",
};

export const main = {
  50: "#F2E6E4",
  100: "#E4CDC8",
  200: "#C8978E",
  300: "#AE6557",
  400: "#754339",
  500: "#3C221D",
  600: "#301B17",
  700: "#261612",
  800: "#180E0C",
  900: "#0E0807",
  950: "#070403",
};

export const primary = {
  50: "#F6F1EF",
  100: "#EEE5E2",
  200: "#DCC9C1",
  300: "#CCAFA4",
  400: "#B99283",
  500: "#A97966",
  600: "#8C604F",
  700: "#6F4C3E",
  800: "#4E362C",
  900: "#30211B",
};

export const secondary1 = {
  50: "#FFF5EB",
  100: "#FFEEDB",
  200: "#FFDEB8",
  300: "#FECD94",
  400: "#FEBC71",
  500: "#FEAA4B",
  600: "#FE8D0C",
  700: "#C66A01",
  800: "#844701",
  900: "#422300",
};

export const secondary2 = {
  50: "#FCF8F8",
  100: "#F9F1F2",
  200: "#F3E2E5",
  300: "#EFD7DB",
  400: "#E9C9CE",
  500: "#E3BBC1",
  600: "#CB818C",
  700: "#B14958",
  800: "#74303A",
  900: "#3A181D",
};

export const bg = {
  50: "#FAF9FA",
  100: "#F6F4F6",
  200: "#EDE8ED",
  300: "#E6E0E6",
  400: "#DDD4DD",
  500: "#BEACBE",
  600: "#9E859E",
  700: "#785F78",
  800: "#503F50",
  900: "#282028",
};

export const text = {
  main: "black",
  invert: "white",
  50: "#eceffe",
  100: "#d3d6e1",
  200: "#b8bbc7",
  300: "#9da1af",
  400: "#828897",
  500: "#686e7d",
  600: "#505762",
  700: "#383d47",
  800: "#20232d",
  900: "#060916",
};

export const _theme: DeepPartial<ChakraTheme> = {
  fonts: {
    heading: "Inter Variable, sans-serif",
    body: "Inter Variable, sans-serif",
  },
  colors: {
    primary,
    secondary1,
    secondary2,
    bg,
    text,
    white,
    main,
  },
};

export default extendTheme(_theme) as ChakraTheme;
