import { OfferModal } from "@lib/components/OfferModal";
import React from "react";
import { Offer, OfferID, offerIDs } from "src/types";
import { useExitIntentProvider } from "./ExitIntentProvider";
import { useLocation } from "@gatsbyjs/reach-router";
import { useStore } from "./StoreProvider";

type OfferCtx = {
  offerID: OfferID;
};

const OfferContext = React.createContext<OfferCtx | null>(null);

export function useOfferProvider() {
  const context = React.useContext(OfferContext);
  if (!context) {
    throw new Error("useOfferProvider must be used within an OfferProvider");
  }
  return context;
}

const DEFAULT_OFFER_ID: OfferID = "offer1";

export function OfferProvider({ children }: React.PropsWithChildren) {
  const location = useLocation();
  const { pricingPlans } = useStore();
  const { setIsViewEnabled } = useExitIntentProvider();

  const [offer, setOffer] = React.useState<Offer | null>(null);
  const [offerID, setOfferID] = React.useState<OfferID>(DEFAULT_OFFER_ID);

  React.useEffect(() => {
    if (location.pathname.includes("/summary")) {
      const offerIDOverwrite = checkForOfferIDOverwrite();
      if (offerIDOverwrite) {
        setOfferID(offerIDOverwrite);
        setIsViewEnabled(false);

        const offer = pricingPlans
          .flatMap((it) => it.offers)
          .filter((o) => o.id === offerIDOverwrite)
          .sort((a, b) => b.percent_off - a.percent_off)[0];

        if (!offer) {
          console.warn("Offer not found");
          return;
        }

        setOffer(offer);
      }
    }
  }, [pricingPlans]);

  function checkForOfferIDOverwrite() {
    const params = new URLSearchParams(location.search);
    const param = params.get("offerID");
    if (!param) {
      return;
    }

    const offerID = offerIDs.find((it) => it === param);
    if (!offerID) {
      return;
    }

    return offerID;
  }

  const value = React.useMemo<OfferCtx>(() => {
    return {
      offerID,
    };
  }, [offerID]);

  return (
    <OfferContext.Provider value={value}>
      {children}

      {offer && <OfferModal isOpen={true} onClose={() => setOffer(null)} offer={offer} />}
    </OfferContext.Provider>
  );
}
