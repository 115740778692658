import React from "react";

import { useStore } from "./StoreProvider";

export function RedirectsProvider(props: React.PropsWithChildren) {
  const { isLoading, user } = useStore();

  React.useEffect(() => {
    if (typeof window !== undefined && !isLoading) {
      const isFunnelPath = window.location.href.includes("/funnel-1");
      const isFunnelQuizPath = window.location.href.includes("/funnel-1/quiz");
      const isFunnelFirstQuizStepPath = window.location.href.includes("slideid=bot-id");

      if (isFunnelQuizPath && user) {
        console.warn(`Redirecting to summary page: user alredy exists`);
        window.location.href = `${window.location.origin}/funnel-1/summary`;
        return;
      }

      if (isFunnelPath && !isFunnelFirstQuizStepPath && !user) {
        console.warn(`Redirecting to home page: user is missing`);
        window.location.href = `${window.location.origin}/funnel-1/quiz?slideid=bot-id`;
        return;
      }
    }
  }, [isLoading]);

  return props.children;
}
