import * as React from "react";
import { Box, Button, Flex, Icon, Stack } from "@chakra-ui/react";
import { FaArrowLeft } from "react-icons/fa6";

import { MdCreditCard } from "react-icons/md";
import { ExpressCheckoutElement, PaymentElement } from "@stripe/react-stripe-js";
import { ErrorView, LoadingView } from "./components";
import { PricingPlan } from "src/types";
import { usePayment } from "./hooks";

export function PaymentForm({ plan }: { plan: PricingPlan }) {
  const [paymentType, setPaymentType] = React.useState<"card" | "initial">("initial");
  const [expressCheckoutReady, setExpressCheckoutReady] = React.useState(false);
  const [cardCheckoutReady, setCardCheckoutReady] = React.useState(false);
  const [payment, submitPayment] = usePayment();

  const isReady = cardCheckoutReady && expressCheckoutReady;

  return (
    <Stack>
      {payment.state === "error" && <ErrorView text={payment.error} />}

      {!isReady && <LoadingView text="Loading secure payment form..." />}

      <Box display={isReady ? "block" : "none"}>
        {paymentType === "card" && (
          <Button onClick={() => setPaymentType("initial")} size="sm" mb={3}>
            <Icon as={FaArrowLeft} />
          </Button>
        )}

        <Box display={paymentType === "initial" ? "block" : "none"}>
          <ExpressCheckoutElement
            options={{
              layout: { maxColumns: 1, maxRows: 5 },
            }}
            onReady={() => setExpressCheckoutReady(true)}
            onConfirm={() => {
              submitPayment("express", plan);
            }}
          />

          <Button
            size="lg"
            colorScheme="blue"
            width={"full"}
            mt={4}
            leftIcon={<Icon as={MdCreditCard} />}
            onClick={() => setPaymentType("card")}
          >
            Credit or debit card
          </Button>
        </Box>

        <Flex
          display={paymentType === "card" ? "flex" : "none"}
          direction={"column"}
          gap={4}
          alignItems={"start"}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitPayment("card", plan);
            }}
            style={{ width: "100%" }}
          >
            <PaymentElement
              onReady={() => setCardCheckoutReady(true)}
              options={{
                wallets: { applePay: "never", googlePay: "never" },
                layout: "tabs",
                terms: { card: "never" },
              }}
            />

            <Button
              type="submit"
              colorScheme="blue"
              width={"full"}
              size="lg"
              mt={4}
              isLoading={payment.state === "loading"}
            >
              Pay Now
            </Button>
          </form>
        </Flex>
      </Box>
    </Stack>
  );
}
