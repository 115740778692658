import * as React from "react";
import { sessionCache } from "src/sessionCache";
import { coreapi, ServerBot, ServerPricingPlan, ServerUser } from "@services/coreapi";
import { useLocation } from "@gatsbyjs/reach-router";

type Store = {
  bots: ServerBot[];
  pricingPlans: ServerPricingPlan[];
  user?: ServerUser;
  setUser: (user: ServerUser) => void;
  setOfferTime: (time: number) => void;
  offerTime: number;
  isLoading: boolean;
};

const StoreContext = React.createContext<Store | null>(null);

export function useStore(): Store {
  const ctx = React.useContext(StoreContext);
  if (!ctx) {
    throw new Error("useStore must be used within StoreContext");
  }

  return ctx;
}

const DEFAULT_OFFER_TIME = 899000;

export function StoreProvider(props: React.PropsWithChildren) {
  const location = useLocation();

  const [pricingPlans, setPricingPlans] = React.useState<ServerPricingPlan[]>([]);
  const [bots, setBots] = React.useState<ServerBot[]>([]);
  const [offerTime, setOfferTime] = React.useState<number>(DEFAULT_OFFER_TIME);
  const [user, setUser] = React.useState<ServerUser | undefined>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    async function getData() {
      setIsLoading(true);

      await getAllBots();
      await getPricingPlans();
      await getUser();

      setIsLoading(false);
    }

    getData();
  }, []);

  React.useEffect(() => {
    const cache = sessionCache.getRootState();
    if (user && !cache?.user) {
      sessionCache.setRootState({ user });
    }
  }, [user]);

  async function getUser() {
    const searchParams = new URLSearchParams(location.search);
    const userID = searchParams.get("userID");

    if (userID) {
      const user = await coreapi.getUserByID(userID);
      setUser(user);
    } else {
      const cache = sessionCache.getRootState();
      if (cache?.user) {
        setUser(cache.user);
      }
    }
  }

  async function getAllBots() {
    const bots = await coreapi.getAllBots();

    setBots(bots);
  }

  async function getPricingPlans() {
    const pricingPlans = await coreapi.getPricingPlans();
    setPricingPlans(pricingPlans);
  }

  const value = React.useMemo<Store>(() => {
    return {
      bots,
      pricingPlans,
      offerTime,
      user,
      setUser,
      setOfferTime,
      isLoading,
    };
  }, [offerTime, bots, pricingPlans, user, isLoading]);

  return <StoreContext.Provider value={value}>{props.children}</StoreContext.Provider>;
}
