import { posthog } from "posthog-js";
import { isProdMode } from "./isProdMode";
import { siteConfig } from "src/conf";
import { Gender } from "@lovelica/api-client";

type TrackingEventType =
  | "add-payment-info"
  | "tried-to-register-with-existing-email"
  | "profile-gallery-audio-button-clicked"
  | "profile-gallery-next-image-button-clicked"
  | "to-pricing-button-clicked"
  | "purchase"
  | "slide-submitted"
  | "slide-skipped"
  | "slide-entered"
  | "exit-intent-view-opened"
  | "exit-intent-view-accepted"
  | "exit-intent-view-declined"
  | "offer-modal-view-opened"
  | "offer-modal-button-clicked";

export function trackEvent(e: {
  name: TrackingEventType;
  properties: {
    [prop: string]: any;
  };
}) {
  trackGAEvent(e);
  posthog.capture(e.name, e.properties);
  trackBrevoEvent(e.name, e.properties);
}

/**
 * There's no need to call this manually,
 * as we use a Gatsby plugin to initialize the pixel when the page loads.
 * Use this function only if you need to provide additional data to the pixel
 * that you collected later:
 * - user email,
 * - user first name,
 * - etc...
 */
export function initPixel(pixelID: string, properties?: Record<string, any>) {
  if (typeof window === "undefined") {
    return;
  }
  if (!(window as any).fbq) {
    return;
  }
  (window as any).fbq("init", pixelID, properties);
}

export function trackPixelEvent(event: string, properties?: Record<string, any>) {
  if (typeof window === "undefined") {
    return;
  }
  if (!(window as any).fbq) {
    return;
  }
  (window as any).fbq("track", event, properties);
}

export function trackCustomPixelEvent(event: string, properties?: Record<string, any>) {
  if (typeof window === "undefined") {
    return;
  }
  if (!(window as any).fbq) {
    return;
  }
  (window as any).fbq("trackCustom", event, properties);
}

export function initPosthog(token: string, apiHost: string, feVersion: string) {
  posthog.init(token, {
    api_host: apiHost,
    capture_pageview: false,
    autocapture: false,
    debug: !isProdMode(),
    disable_session_recording: !isProdMode(),
    advanced_disable_feature_flags_on_first_load: true,
    advanced_disable_feature_flags: true,
    bootstrap: {
      featureFlags: {},
    },
  });

  posthog.register({
    frontend_version: feVersion,
    frontend_app: "gatsby-marketing-webapp",
  });

  // Do not track in dev mode
  if (!isProdMode()) {
    posthog.opt_out_capturing();
  }
}

function trackGAEvent(e: {
  name: string;
  properties: {
    [prop: string]: any;
  };
}) {
  if (typeof window === "undefined") {
    return;
  }

  if (!(window as any).dataLayer) {
    return;
  }

  if (!isProdMode()) {
    return;
  }

  const { name, properties } = e;

  (window as any).dataLayer.push({
    event: name,
    ...properties,
  });
}

/**
 * @param value - for $5 pass 5
 */
export function trackGAPurchaseEvent(input: { value: number; currency: string }) {
  if (!(window as any).gtag) {
    console.error("google tag not loaded before the app is loaded");
    return;
  }

  (window as any).gtag("event", "conversion", {
    send_to: `${siteConfig.googleAdsTag}/Ik_oCIuSvqoaELzir4g_`,
    value: input.value,
    currency: input.currency.toUpperCase(),
    transaction_id: "",
  });
}

export function getBrevo(): Array<any> {
  // @ts-expect-error
  window.Brevo = window.Brevo || [];
  // @ts-expect-error
  return window.Brevo;
}

export function initBrevo({ clientKey }: { clientKey: string }) {
  getBrevo().push([
    "init",
    {
      client_key: clientKey,
    },
  ]);
}

export function identifyBrevoUser(props: {
  email: string;
  userID?: string;
  botID?: string;
  gender?: Gender;
  age?: number;
}) {
  const brevo = getBrevo();
  brevo.push(() => {
    // @ts-expect-error
    if (!brevo.identify) {
      return;
    }
    // @ts-expect-error
    brevo.identify({
      identifiers: {
        email_id: props.email,
      },
      attributes: {
        DB_ID: props.userID,
        BOT_ID: props.botID,
        AGE: props.age,
        GENDER: props.gender?.toUpperCase(),
      },
    });
  });
}

function trackBrevoEvent(event: string, properties?: Record<string, any>) {
  const brevo = getBrevo();
  // https://developers.brevo.com/docs/track-custom-events-js
  brevo.push(["track", event, {}, properties]);
}

export function trackBrevoPageView(title: string, path: string) {
  const brevo = getBrevo();
  brevo.push(["page", title, { ma_path: path }]);
}
