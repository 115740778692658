import { graphql, useStaticQuery } from "gatsby";

export type SiteMetadata = {
  brandName: string;
  companyName: string;
  companyAddress: string;
  companyCode: string;
  email: string;
  title: string;
  version: string;
  siteUrl: string;
  webappUrl: string;
  description: string;
  image: string;
};

export const useSiteMetadata = (): SiteMetadata => {
  const data = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          brandName
          companyName
          companyAddress
          companyCode
          title
          version
          email
          siteUrl
          webappUrl
          description
          image
        }
      }
    }
  `);

  return data.site.siteMetadata;
};
