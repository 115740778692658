export function createProductURL(params?: string) {
  return `${process.env.GATSBY_WEBAPP_URL}` + (params ? `?${params}` : "");
}

export function createSuccessCheckoutURL(input: {
  paymentType: "express" | "card";
  amount: number;
  currency: string;
  productID: string;
  priceID: string;
}) {
  // this is a redirect url after the successful payment
  const redirectUrl = new URL(window.location.href);
  redirectUrl.searchParams.delete("checkout");
  redirectUrl.pathname = "/funnel-1/success-checkout";

  redirectUrl.searchParams.set("paymentType", input.paymentType);
  redirectUrl.searchParams.set("amount", input.amount.toString());
  redirectUrl.searchParams.set("currency", input.currency);
  redirectUrl.searchParams.set("priceID", input.priceID);
  redirectUrl.searchParams.set("productID", input.productID);

  return redirectUrl;
}
