import { ServerUser } from "@services/coreapi";
import { SelectorState } from "./lib/quiz-lib";

type Cache = {
  quizState?: Record<string, SelectorState>;
  rootState?: {
    user?: ServerUser;
  };
  purchaseStatus?: boolean;
};

const KEY = "lovelica-session-cache";
const DEFAULT_CACHE: Cache = {};

function getSessionStorage() {
  if (typeof window !== "undefined") {
    return window.sessionStorage;
  }
}

function setCache(input: Partial<Cache>) {
  const cache = getCache() ?? DEFAULT_CACHE;
  getSessionStorage()?.setItem(KEY, JSON.stringify({ ...cache, ...input }));
}

function getCache(): Cache | undefined {
  try {
    const item = getSessionStorage()?.getItem(KEY);
    if (!item) {
      return;
    }

    return JSON.parse(item);
  } catch (err) {
    console.error("Failed to parse data from session storage");
    return DEFAULT_CACHE;
  }
}

export const sessionCache = {
  getQuizState: () => getCache()?.quizState,
  setQuizState: (quizState: Record<string, SelectorState>) => setCache({ quizState }),
  getRootState: () => getCache()?.rootState,
  setRootState: (rootState: { user?: ServerUser }) => setCache({ rootState }),
  getPurchaseStatus: () => getCache()?.purchaseStatus ?? false,
  setPurchaseStatus: (purchaseStatus: boolean) => setCache({ purchaseStatus }),
};
