import {
  Modal,
  ModalBody,
  Text,
  ModalContent,
  ModalOverlay,
  Stack,
  Button,
  Icon,
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import { FaArrowRight } from "react-icons/fa6";

import { Offer } from "src/types";
import { navigate } from "gatsby";
import { useLocation } from "@gatsbyjs/reach-router";
import React from "react";
import { trackEvent } from "@utils/tracking";

export function OfferModal({
  isOpen,
  onClose,
  offer,
}: {
  isOpen: boolean;
  onClose: () => void;
  offer: Offer;
}) {
  const location = useLocation();

  React.useEffect(() => {
    trackEvent({
      name: "offer-modal-view-opened",
      properties: {},
    });
  }, []);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered closeOnOverlayClick={false}>
      <ModalOverlay backgroundColor={"blackAlpha.800"} />

      <ModalContent
        overflow={"hidden"}
        mx={3}
        backgroundColor={"black"}
        borderColor="gray.700"
        borderWidth={"1px"}
        py={5}
      >
        <ModalBody>
          <Stack spacing={0} position={"relative"} alignItems={"center"}>
            <Stack justifyContent={"center"}>
              <Text
                fontWeight={"bold"}
                fontSize={"lg"}
                lineHeight={1.2}
                color="white"
                textAlign={"center"}
                mx={"auto"}
              >
                Up to
              </Text>

              <Text
                fontWeight={"bold"}
                fontSize={"4xl"}
                lineHeight={1.2}
                color="#e46882"
                textAlign={"center"}
                mx={"auto"}
              >
                {offer.percent_off}% Off
              </Text>
              <Text
                fontWeight={"bold"}
                fontSize={"lg"}
                lineHeight={1.2}
                color="white"
                textAlign={"center"}
                mx={"auto"}
              >
                Discount Unlocked! 🔥🔓
              </Text>

              <StaticImage
                src={"../../images/special-offer-3.jpg"}
                alt={"Offer"}
                width={400}
                style={{ maxWidth: "100%" }}
              />
            </Stack>

            <Button
              mt={3}
              size="md"
              borderRadius={"lg"}
              bgGradient={"linear-gradient(to right, #e46882, #9e004f)"}
              _hover={{ bgGradient: "linear-gradient(to right, #e46882, #9e004f)" }}
              _active={{ bgGradient: "linear-gradient(to right, #e46882, #9e004f)" }}
              color={"white"}
              fontSize={"md"}
              px={10}
              onClick={() => {
                trackEvent({
                  name: "offer-modal-button-clicked",
                  properties: {},
                });
                onClose();
                navigate(location.pathname + location.search + "#pricing");
              }}
            >
              Use My Discount
              <Icon as={FaArrowRight} boxSize={3} ml={2} />
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
