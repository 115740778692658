import { edenFetch } from "@elysiajs/eden";
import { CoreServerType } from "@lovelica/api-client";
import { siteConfig } from "src/conf";
import { EnsureUserInput, ServerBot, ServerPricingPlan, ServerUser } from "./types";

const eden = edenFetch<CoreServerType>(siteConfig.coreApiHost);

export const coreapi = {
  createNewUser,
  createSubscription,
  getPricingPlans,
  getAllBots,
  getUserByID,
  getUserByEmail,
  getSecretPricingPlan,
  createCheckoutSessions,
  updateUser,
};

async function getUserByEmail(email: string) {
  return await eden("/getUserByEmail", {
    method: "GET",
    query: {
      email,
    },
  });
}

async function getUserByID(userID: string): Promise<ServerUser | undefined> {
  try {
    const res = await eden("/getUserByID", {
      method: "GET",
      query: {
        id: userID,
      },
    });

    return res.data!;
  } catch (err) {
    console.error(`Failed to fetch: user ${userID} not found`);
    return;
  }
}

async function getAllBots(): Promise<ServerBot[]> {
  const res = await eden("/getAllBots", {});

  return res.data!;
}

async function createNewUser(input: EnsureUserInput) {
  return eden("/createNewUser", {
    method: "POST",
    body: {
      gender: input.gender,
      current_tz_id: Intl.DateTimeFormat().resolvedOptions().timeZone,
      email: input.email,
      age: input.age,
      goal: input.goal,
      bot_id: input.botID,
      bot_emotional_attributes: input.botEmotionalAttributes,
      bot_approach: input.botApproach,
      bot_conversation_depth: input.botConversationDepth,
      bot_body_shape: input.botBodyShape,
      bot_body_upper: input.botBodyUpper,
      bot_body_lower: input.botBodyLower,
      bot_roleplay_theme: input.botRoleplayTheme,
    },
  });
}

async function updateUser(input: EnsureUserInput & { id: string }) {
  return eden("/updateUserProfile", {
    method: "POST",
    body: {
      id: input.id,
      gender: input.gender,
      age: input.age,
      goal: input.goal,
      bot_id: input.botID,
      bot_emotional_attributes: input.botEmotionalAttributes,
      bot_approach: input.botApproach,
      bot_conversation_depth: input.botConversationDepth,
      bot_body_shape: input.botBodyShape,
      bot_body_upper: input.botBodyUpper,
      bot_body_lower: input.botBodyLower,
      bot_roleplay_theme: input.botRoleplayTheme,
    },
  });
}

async function getPricingPlans() {
  const res = await eden("/pricingPlans", {
    method: "GET",
  });
  return res.data!;
}

async function getSecretPricingPlan(): Promise<ServerPricingPlan | null> {
  const res = await eden("/secretPricingPlan", {
    method: "GET",
  });
  return res.data!;
}

async function createSubscription({
  userID,
  priceID,
  couponID,
}: {
  userID: string;
  priceID: string;
  couponID?: string;
}) {
  return eden("/payments/createSubscription", {
    method: "POST",
    body: {
      userID,
      priceID,
      couponID,
    },
  });
}

async function createCheckoutSessions({
  userID,
  prices,
}: {
  userID: string;
  prices: Array<{
    id: string;
    successUrl: string;
    cancelUrl: string;
  }>;
}) {
  return eden("/payments/createCheckoutSessions", {
    method: "POST",
    body: {
      userID,
      prices,
    },
  });
}
