import * as React from "react";
import "../global.css";
import { GlobalHead } from "../GlobalHead";
import { LocationProvider } from "@gatsbyjs/reach-router";
import * as Sentry from "@sentry/gatsby";
import posthog from "posthog-js";
import { RedirectsProvider } from "../providers/RedirectsProvider";
import { StoreProvider } from "src/providers/StoreProvider";
import { ExitIntentProvider } from "src/providers/ExitIntentProvider";

/** Wraps every page but is not re-mounted when chaning pages */
export function RootWrapper(props: React.PropsWithChildren) {
  React.useEffect(() => {
    // Additional data passed to Sentry that ensures easier issue debugging
    const posthogID = posthog.get_distinct_id();
    if (posthogID) {
      Sentry.setUser({
        posthogID: posthogID,
      });
    }
  }, []);

  return (
    <LocationProvider>
      <StoreProvider>
        <RedirectsProvider>
          <ExitIntentProvider>
            <GlobalHead />
            {props.children}
          </ExitIntentProvider>
        </RedirectsProvider>
      </StoreProvider>
    </LocationProvider>
  );
}
